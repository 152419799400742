import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";

interface Props {
  images?: Image[];
}

const ImageGallery: React.FC<Props> = ({ images }) => (
  <div className="image-gallery mw hs vs-xl">
    <div className="container">
      {images &&
        images.map((image, index) => (
          <div className="image" key={index}>
            <img src={image.url} alt={image.altText} />
          </div>
        ))}
    </div>
  </div>
);

export default ImageGallery;
